import Storage from 'react-native-storage'
import en from '../config/translations/en.json'
import de from '../config/translations/de.json'
import config from '../config/config'

class TranslationService {
  constructor (translations, locale = 'de') {
    this.translations = translations
    this.listeners = []
    this.setLocale(locale)

    this.storage = new Storage({
      size: 1000,
      storageBackend: localStorage || window.localStorage,
      defaultExpires: 1000 * 3600 * 24 * 30,
      enableCache: true,
      sync: {
      }
    })

    this.loadLocale()
  }

  translate (str) {
    if (this.translations[this.currentLocale][str] === '') {
      return ''
    }
    return this.translations[this.currentLocale][str] || str
  }

  getLocale () {
    return this.currentLocale
  }

  setLocale (locale) {
    this.currentLocale = locale

    this.notifyChange()
  }

  saveLocale (locale) {
    this.storage.save({
      key: 'currentLocale',
      data: this.currentLocale
    })
  }

  async loadLocale () {
    try {
      const locale = await this.storage.load({
        key: 'currentLocale'
      })

      this.currentLocale = locale
      this.notifyChange()
    } catch (err) { }
  }

  getLocales () {
    return Object.keys(this.translations)
  }

  onChange (callback) {
    this.listeners.push(callback)
  }

  notifyChange () {
    for (let listener of this.listeners) {
      listener(this.currentLocale)
    }
  }
}

const translationServiceInstance = new TranslationService({ de, en }, config.locale)
const t = translationServiceInstance.translate.bind(translationServiceInstance)

export default translationServiceInstance
export { t }
