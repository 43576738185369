import React, { Component } from 'react'
import assert from 'browser-assert'
import { Map, Marker } from '../lib/Map'
import config from '../config/config'
import Geolocation from '../lib/Geolocation'
import { regionSurroundingPosition } from '../lib/utils/regionMath'
import { withTimeout, TimeoutError, isOnline } from '../lib/utils/network'
import { sanitizeReport } from '../models/SavedReportsService'
import ListItemLayoutView from "./ListItemLayoutView";
import ActivityIndicator from './ActivityIndicator'
import { ReactComponent as QuoteIcon } from "../assets/quote.svg"
import { ReactComponent as CameraIcon } from "../assets/camera.svg"
import { openDatabase, storeReport } from '../models/ReportStore'

class SubmitReportView extends Component {
  constructor (props) {
    super(props)

    const { newReport } = props

    this.imageOptions = {
      title: props.t('SCREENS.SUBMIT_REPORT.ADD_IMAGE'),
      storageOptions: {
        skipBackup: true,
        path: 'images'
      }
    }

    this.state = {
      image: { uri: null, type: null},
      position: null,
      performingPositionDetection: false,
      errorMessage: '',
      region: config.fallbackRegion,
      submitError: false,
      submittingReport: false,
      savingReport: false
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onSave = this.onSave.bind(this)
    this.onUserLocationChange = this.onUserLocationChange.bind(this)
    this.onImageSelected = this.onImageSelected.bind(this)
  }

  isReportValid () {
    const { newReport } = this.props
    return newReport && newReport.eventType
  }

  componentDidMount () {
    if (!this.isReportValid() && this.props.onInvalidReport) {
      this.props.onInvalidReport()
    }

    const success = this.setLocationFromUserLocation()
    if (!success) {
      this.detectPosition()
    }

    window.IconicJS().inject('.submit-report-view img.iconic')
  }

  setLocationFromUserLocation (userLocation) {
    let location = this.props.userLocation

    if (userLocation) {
      location = userLocation
    }

    const { requiredAccuracy } = config
    if (location && location.coords && location.timestamp) {
      const { coords, timestamp } = location
      if (coords && timestamp) {
        const { accuracy } = coords

        if (accuracy < requiredAccuracy) {
          const { latitude, longitude } = coords
          this.setState({
            position: {
              longitude,
              latitude
            }
          })
          return true
        } else {

        }
      }
    }

    return false
  }

  async detectPosition () {
    const { t } = this.props
    const { positionDetectionOptions, timeouts, requiredAccuracy } = config

    const promise = new Promise((resolve, reject) => {
      Geolocation.getCurrentPosition((result) => {
        resolve(result)
      }, (errors) => {
        reject(errors)
      }, positionDetectionOptions
      )
    })

    try {
      this.setState({
        performingPositionDetection: true,
        errorMessage: ''
      })

      const result = await withTimeout(
        timeouts.positionDetection,
        new TimeoutError('timeout while detecting position'),
        promise
      )

      const { coords } = result
      const { accuracy } = coords
      if (accuracy < requiredAccuracy) {
        this.setState({
          position: coords
        })
      } else {
        if (!this.state.position) {
          this.setState({
            errorMessage: t('SCREENS.SUBMIT_REPORT.LOCATION_ERROR')
          })
        }
      }
    } catch (e) {
      if (!this.state.position) {
        this.setState({
          errorMessage: t('SCREENS.SUBMIT_REPORT.LOCATION_ERROR')
        })
      }
    } finally {
      this.setState({
        performingPositionDetection: false
      })
    }
  }

  async onSubmit (e) {
    e.preventDefault()

    const { t } = this.props

    // if (!isOnline()) {
    //   this.setState({
    //     errorMessage: t('SCREENS.SUBMIT_REPORT.NETWORK_ERROR') +
    //       (this.provideSaveFunctionality() ? (' ' + t('SCREENS.SUBMIT_REPORT.RETRY_OR_SAVE')) : ''),
    //     submitError: true
    //   })

    //   return
    // }

    const { latitude, longitude } = this.state.position
    const newReport = { ...this.props.newReport }
    newReport.note = this.commentsInput.value
    newReport.latitude = latitude
    newReport.longitude = longitude

    this.submitReport(newReport)
  }

  async onSave () {
    const { newReport, savedReportsService, t, formatDate4Api } = this.props
    const report = Object.assign({}, newReport)

    const { latitude, longitude } = this.state.position
    report.note = this.commentsInput.value
    report.latitude = latitude
    report.longitude = longitude
    report.createdAt = formatDate4Api(new Date())

    if (this.state.image && this.state.image.uri && this.state.image.type) {
      report.imageUrl = this.state.image.uri
      report.imageType = this.state.image.type
    }

    try {
      this.setState({
        errorMessage: ''
      })

      await savedReportsService.add(report)

      if (this.props.onSave) {
        this.props.onSave()
      }
    } catch (e) {
      this.setState({
        errorMessage: t('SCREENS.SUBMIT_REPORT.SAVE_ERROR')
      })
    }
  }

  async submitReport (aReport) {
    const report = sanitizeReport(aReport) // also handle saved reports
    const { image } = this.state

    if ('serviceWorker' in navigator && 'SyncManager' in window) {
      navigator.serviceWorker.ready.then(async registration => {
        const db = await openDatabase()
        const res1 = await storeReport(db, report, image)
        try {
          await registration.sync.register('sync-reports')
        } catch {
          console.log("Background Sync could not be registered!")
        }
        if (this.props.onSubmit) {
          this.props.onSubmit(aReport)
        }
      })
    } else {
      const { createReport, createReportImage, t } = this.props
      const { timeouts } = config

      try {
        this.setState({
          submittingReport: true,
          errorMessage: ''
        })

        // validate report

        const valid = true // reportBuilder.validate()
        assert(valid, 'validation error')

        // submit report to api
        const result = await withTimeout(
          timeouts.createReport,
          new TimeoutError('timeout while submitting report'),
          createReport(report)
        )

        if (!result.report || !result.imageUploadToken) {
          this.setState({
            submitError: true,
            errorMessage: t('SCREENS.SUBMIT_REPORT.UNEXPECTED_ERROR') +
              this.provideSaveFunctionality() ? ' ' + t('SCREENS.SUBMIT_REPORT.RETRY_OR_SAVE') : ''
          })
        }

        const { imageUploadToken, report: { uid } } = result

        // if there was an image selected, upload it
        const { uri, type } = image
        if (uri && type) {
          await withTimeout(
            timeouts.uploadReportImage,
            new TimeoutError('timeout while submitting report image'),
            createReportImage(uid, imageUploadToken, image)
          )
        }
        this.setState({
          submittingReport: false
        })
        if (this.props.onSubmit) {
          this.props.onSubmit(aReport)
        }
      } catch (e) {
        let message

        if (e instanceof TimeoutError) {
          message = t('SCREENS.SUBMIT_REPORT.NETWORK_ERROR') +
            (this.provideSaveFunctionality() ? (' ' + t('SCREENS.SUBMIT_REPORT.RETRY_OR_SAVE')) : '')
        }

        this.setState({
          submitError: true,
          errorMessage: message ||
            (
              t('SCREENS.SUBMIT_REPORT.UNEXPECTED_ERROR') +
                (this.provideSaveFunctionality() ? (' ' + t('SCREENS.SUBMIT_REPORT.RETRY_OR_SAVE')) : '')
            )
        })

        this.setState({
          submittingReport: false
        })
      }
    }
  }

  onImageSelected (e) {
    const files = this.fileUpload.files
    if (!files || files.length === 0) return

    const file = files[0]
    const { type } = file

    const reader = new FileReader()

    reader.onload = (e) => {
      const uri = e.target.result
      const image = { uri, type }
      this.setState({ image: image})
    };

    reader.readAsDataURL(file);
  }

  submitButtonEnabled = () => {
    const enabled = this.state.position && !this.state.submittingReport
    return enabled
  }

  provideSaveFunctionality = () => false // this.props.savedReportsService
  saveButtonEnabled = () => true // this.provideSaveFunctionality() && this.state.position && !this.state.savingReport
  saveButtonVisible = () => false // this.provideSaveFunctionality() && this.state.submitError

  render () {
    const { t,
      //  newReport, reportPresentation
      title,
      subtitle,
      step
    } = this.props

    const { position } = this.state
    const region = regionSurroundingPosition(position)

    const { image } = this.state
    const hasImage = image && image.uri && image.type

    const { submittingReport } = this.state
    const submitButtonClass = submittingReport ? 'is-loading' : ''
    const submitButtonDisabled = !this.submitButtonEnabled()

    const { savingReport } = this.state
    const saveButtonClass = savingReport ? 'is-loading' : ''
    const saveButtonDisabled = !this.saveButtonEnabled()

    return !this.isReportValid() ? (<div />) : (
      <form onSubmit={this.onSubmit}>
        <ListItemLayoutView
          text={step}
          isActive={true}
          showLineTop={true}
          showLineBottom={false}
        >
        <div className="submit-report-view">
          <div className="pl-0 sm:pl-8 justify-center flex flex-col">
            <h3 className="text-lg sm:text-xl font-bold">{title}</h3>
            <p className="py-4">{subtitle}</p>
            {/* <div className="submit-report-view__report-details">
              <ReportDetails
                  report={newReport}
                  reportPresentation={reportPresentation}
                  t={t}
              />
            </div> */}


              {/* <h2 className="h2">{t('SCREENS.SUBMIT_REPORT.IMAGE_HEADING')}</h2> */}
              { hasImage && <div className="submit-report-view__image"><img src={image.uri} alt="weather event" /></div> }
              <div className="file my-4">
                <label className="file-label">
                  <input className="file-input sr-only" type="file" accept="image/*" name="resume" ref={(ref) => this.fileUpload = ref} onChange={this.onImageSelected} capture />
                  <span className="file-cta btn-secondary btn-icon-label-inline text-petrol">
                    <span className="file-icon">
                      {/* <img data-src={cameraIcon} className="iconic iconic-sm iconic--dampened" alt="Kamera" /> */}
                      <CameraIcon className="h-12 text-petrol stroke-current fill-current" />
                    </span>
                    <span className="file-label">
                      { t('SCREENS.SUBMIT_REPORT.ADD_IMAGE') }
                    </span>
                  </span>
                </label>
              </div>

              {/* <h2 className="h2">{t('SCREENS.SUBMIT_REPORT.USER_NOTES_HEADING')}</h2> */}
              <div className="field">
                <div className="flex items-top">
                  <span className="mr-2">
                    <QuoteIcon className="text-gray-lighter stroke-current fill-current" />
                    {/* <img data-src={quoteIcon} data-quote-direction="left" className="iconic iconic-sm iconic--dampened" alt="Kommentar" /> */}
                  </span>
                  <textarea className="w-full border p-4 text-gray-lighter border-gray-lightest" placeholder={t('SCREENS.SUBMIT_REPORT.USER_NOTES_HEADING')} ref={(ref) => this.commentsInput = ref} />
                </div>
              </div>

              <div className="submit-report-view__map pointer-events-none mt-8 mb-4 w-full h-96 relative border border-gray-lightest">
                {/* <h2 className="h2">{t('SCREENS.SUBMIT_REPORT.LOCATION_HEADING')}</h2> */}
                { !!position && (
                  <Map
                    region={region}
                    showsUserLocation
                    zoomEnabled={false}
                    zoomControlEnabled={false}
                    scrollEnabled={false}
                    onUserLocationChange={this.onUserLocationChange}
                  >
                    { position &&
                      <Marker coordinate={position} />
                    }
                  </Map>
                )}

                { this.state.performingPositionDetection && (
                  <div className="submit-report-view__message">
                    <ActivityIndicator />
                    <span>{t('SCREENS.SUBMIT_REPORT.LOCATION_DETERMINING')}</span>
                  </div>
                )}
              </div>
          </div>
        </div>
        </ListItemLayoutView>
        <div className="flex flex-col justify-center space-y-4 py-8 ml-2 sm:ml-24">
          <div className="submit-report-view__error-message">
            { this.state.errorMessage.length > 0 && (
              <div className="submit-report-view__message">{this.state.errorMessage}</div>
            )}
          </div>

          <div className="submit-report-view__buttons field buttons">
            <button
              className={`btn-primary ${submitButtonClass}`}
              type="submit"
              disabled={submitButtonDisabled}
            >
              {t('SCREENS.SUBMIT_REPORT.BUTTON_SUBMIT')}
            </button>

            { this.saveButtonVisible() &&
              <button
                onClick={this.onSave}
                className={`btn-primary ${saveButtonClass}`}
                type="button"
                disabled={saveButtonDisabled}
              >
                {t('SCREENS.SUBMIT_REPORT.SAVE_RETRY')}
              </button> }
          </div>
        </div>
      </form>
    )
  }

  onUserLocationChange (event) {
    const { setUserLocation } = this.props
    const { coords, timestamp } = event
    setUserLocation({ coords, timestamp })
    this.setLocationFromUserLocation(coords)
  }
}

export default SubmitReportView
