import { openDB } from 'idb';

const databaseName = 'wm-reports';
const databaseVersion = 1;

async function openDatabase() {
    const upgrade = (db) => {
        db.createObjectStore('reports', { keyPath: 'id', autoIncrement: true });
    }

    const options = { upgrade };
    return openDB(databaseName, databaseVersion, options);
}

async function storeReport(db, report, image) {
    let reportRecord = {
        ...report
    }

    if (image && image.uri && image.type) {
        reportRecord.imageUri = image.uri;
        reportRecord.imageType = image.type;
    }

    return db.put('reports', reportRecord);
}

async function getReports(db) {
   return db.getAll('reports');
}


async function deleteReport(db, id) {
    return db.delete('reports', id);
}

export {
    openDatabase,
    storeReport,
    getReports,
    deleteReport
}
