import Storage from 'react-native-storage'
import { withTimeout, TimeoutError } from '../lib/utils/network'

class SavedReportsService {
  static SUBMIT_REPORTS_INTERVAL = 60 * 1000 // in ms

  constructor (params) {
    const { getReports, setReports, createReport, createReportImage, timeoutCreateReport, timeoutUploadReportImage } = params

    this.getReports = getReports
    this.setReports = setReports
    this.createReport = createReport
    this.createReportImage = createReportImage
    this.timeoutCreateReport = timeoutCreateReport
    this.timeoutUploadReportImage = timeoutUploadReportImage

    this.storage = new Storage({
      size: 1000,
      storageBackend: localStorage || window.localStorage,
      defaultExpires: 1000 * 3600 * 24 * 7,
      enableCache: true,
      sync: {
      }
    })

    this.maxId = 0

    this.submittingReport = null
    this.checkAndSubmitReport = this.checkAndSubmitReport.bind(this)
    this.checkAndSubmitReportHandle = setInterval(this.checkAndSubmitReport, SavedReportsService.SUBMIT_REPORTS_INTERVAL)
  }

  async loadAllReportsFromStorage () {
    let reports = []

    try {
      reports = await this.storage.getAllDataForKey('report')

      // determine maxId
      let maxId = this.maxId
      reports.forEach((report) => {
        if (report.localId > maxId) {
          maxId = report.localId
        }
      })
      this.maxId = maxId

      // sort reports
      const sortedReports = reports.sort((a, b) => {
        if (a.createdAt > b.createdAt) return -1
        if (a.createdAt < b.createdAt) return 1
        return 0
      })

      reports = sortedReports
      this.setReports(reports)
    } catch (e) {
    }

    return reports
  }

  async add (report) {
    const savedReport = await this.saveToStorage(report)

    this.setReports([
      savedReport,
      ...this.getReports()
    ])

    return savedReport
  }

  async remove (localId) {
    const reports = this.getReports()
    const index = reports.findIndex((report) => {
      return report.localId === localId
    })

    if (index < 0) return

    this.setReports([
      ...this.getReports().slice(0, index),
      ...this.getReports().slice(index + 1)
    ])

    return this.removeFromStorage(localId)
  }

  // private

  async saveToStorage (report) {
    const id = report.localId || ++this.maxId
    const reportData = Object.assign({}, report, {
      localId: id
    })
    await this.storage.save({
      key: 'report',
      id,
      data: reportData
    })
    return reportData
  }

  async removeFromStorage (localId) {
    this.storage.remove({
      key: 'report',
      id: localId
    })
  }

  async checkAndSubmitReport () {
    if (this.submittingReport) return

    this.submittingReport = true

    const reports = this.getReports()

    if (reports.length > 0) {
      const report = reports[0]
      const { localId } = report

      try {
        await this.submitReport(report)
        await this.remove(localId)
      } catch (e) { } finally { }
    }

    this.submittingReport = false
  }

  async submitReport (savedReport) {
    const report = sanitizeReport(savedReport)
    const result = await withTimeout(
      this.timeoutCreateReport,
      new TimeoutError('timeout while submitting report'),
      this.createReport(report)
    )

    if (!result.report || !result.imageUploadToken) {
      throw Error('error submitting report')
    }

    // if there is an image, upload it
    const { imageUrl, imageType } = savedReport
    if (imageUrl && imageType) {
      const { imageUploadToken, report: { uid } } = result
      const image = {
        uri: imageUrl,
        type: imageType
      }
      await withTimeout(
        this.timeoutUploadReportImage,
        new TimeoutError('timeout while submitting report image'),
        this.createReportImage(uid, imageUploadToken, image)
      )
    }
  }
}

function sanitizeReport (aReport) {
  const report = Object.assign({}, aReport)

  if (report.localId) {
    delete report.localId
  }

  if (report.imageUrl) {
    delete report.imageUrl
  }

  if (report.imageType) {
    delete report.imageType
  }

  return report
}

export default SavedReportsService

export { sanitizeReport }
