import assert from 'browser-assert'
import Storage from 'react-native-storage'
import jwtDecode from 'jwt-decode'

class AuthService {
  // Constructs an auth report service
  // The root url of the app api (for example https://awob.simplebytes.at/api/v1)
  // without the trailing slash is a required parameter
  constructor (appApiRoot, idApiRoot, setUser, locale = 'de-AT') {
    assert(appApiRoot, 'appApiRoot is required')
    assert(idApiRoot, 'appApiRoot is required')
    this.appApiRoot = appApiRoot
    this.idApiRoot = idApiRoot
    this.setUser = setUser
    this.token = null
    this.profile = null

    if (locale === 'en') {
      this.locale = 'en-US'
    } else if (locale === 'de') {
      this.locale = 'de-AT'
    }

    if (typeof localStorage != 'undefined') {
      this.storage = new Storage({
        size: 1000,
        storageBackend: localStorage || window.localStorage,
        defaultExpires: 1000 * 3600 * 24 * 30,
        enableCache: true,
        sync: {
        }
      })
    }
  }

  async loadFromStorage () {
    const promise = Promise.all([
      this.storage.load({
        key: 'token'
      }),
      this.storage.load({
        key: 'profile'
      })
    ])

    promise.then((results) => {
      const [token, profile] = results
      if (token && profile) {
        const expired = this.isTokenExpired(token)
        if (expired) {
          this.logout()
          this.setUser({ username: null })
        } else {
          this.token = token
          this.sendTokenToServiceWorker()
          this.profile = profile
      }
      }
    }).catch((e) => {
      this.logout()
      this.setUser({ username: null })
    })


    return promise
  }

  async saveToStorage () {
    return Promise.all([
      this.storage.save({
        key: 'token',
        data: this.token
      }),
      this.storage.save({
        key: 'profile',
        data: this.profile
      })
    ])
  }

  // gets a jwt acccess token
  // if the user is logged in, a personal access token is returned
  // a client token is returned otherwise
  async getToken () {
    if (this.token) {
      if (this.isTokenExpired(this.token)) {
        this.logout()
        this.setUser({ username: null })
      } else {
      return this.token
      }
    }
    return this.getClientToken()
  }

  // gets a client (non-personalized) token
  async getClientToken () {
    const url = this.absoluteAppApiUrl('token.php')
    const headers = this.headers()
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers
      })

      const data = await response.json()
      if (data && data.access_token) {
        return data.access_token
      } else {
        return null
      }
    } catch(e){
      return null
    }
  }

  // gets the users profile, if logged in
  getProfile () {
    return this.profile
  }

  // registers a user
  // if validateOnly is true, parameters are only validated and no user will be created
  // returns a promise that resolves to true on success
  async register (user, validateOnly = false) {
    const url = this.absoluteIdApiUrl('user/register')
    const headers = this.headers()

    const payload = Object.assign({}, user, {
      validateOnly,
      language: this.locale
    })

    const body = JSON.stringify(payload)

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body
    })
    const data = await response.json()
    return data
  }

  // logs in the user and gets a (personalized) jwt access token
  // returns a promise that resolves to true on success
  async login (login, password) {
    const url = this.absoluteIdApiUrl('user/login')
    const headers = this.headers()
    const body = JSON.stringify({
      login,
      password,
      language: this.locale
    })
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body
    })
    const data = await response.json()
    if (data.access_token) {
      this.token = data.access_token
      this.sendTokenToServiceWorker()
      this.profile = {
        username: login
      }
      try {
        this.saveToStorage()
      } catch (e) {}
    }
    return data
  }

  // changes a user's password
  // if validateOnly is true, parameters are only validated and the password will not be changed
  // returns { "success": true } on success and an object with an error property if there were errors
  async changePassword (passwordCurrent, password, validateOnly = false) {
    const url = this.absoluteIdApiUrl('user/changepwd')

    if (!this.token) {
      return Promise.reject(new Error('login required'))
    }

    const headers = this.headers(this.token)
    const body = JSON.stringify({
      password,
      passwordCurrent,
      validateOnly,
      language: this.locale
    })
    const response = await fetch(url, {
      method: 'PUT',
      headers,
      body
    })
    const data = await response.json()
    return data
  }

  // gets the user's profile data
  // returns { "profile": ... } on success and an object with an error property if there were errors
  async fetchProfile () {
    const url = this.absoluteIdApiUrl('profile')

    if (!this.token) {
      return Promise.reject(new Error('login required'))
    }

    const headers = this.headers(this.token)

    const response = await fetch(url, {
      method: 'GET',
      headers,
    })
    const data = await response.json()
    return data
  }

  // updates the user'S profile
  // if validateOnly is true, parameters are only validated and the profile will not be changed
  // returns { "profile": ... } on success and an object with an error property if there were errors
  async updateProfile (profile, validateOnly = false) {
    const url = this.absoluteIdApiUrl('profile')

    if (!this.token) {
      return Promise.reject(new Error('login required'))
    }

    const headers = this.headers(this.token)
    const payload = Object.assign({}, profile, {
      validateOnly,
      language: this.locale
    })
    const body = JSON.stringify(payload)
    const response = await fetch(url, {
      method: 'PUT',
      headers,
      body
    })
    const data = await response.json()
    return data
  }

  // checks if a user is logged in
  isLoggedIn () {
    return !!this.token
  }

  // logs out the current user
  logout () {
    this.token = null
    this.sendTokenToServiceWorker()
    try {
      this.storage.remove({
        key: 'token'
      })
      this.storage.remove({
        key: 'profile'
      })
    } catch (e) {}
  }

  // private

  headers (token = null) {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }

    return headers
  }

  absoluteAppApiUrl (relativeUrl) {
    return `${this.appApiRoot}/${relativeUrl}`
  }

  absoluteIdApiUrl (relativeUrl) {
    return `${this.idApiRoot}/${relativeUrl}`
  }

  isTokenExpired (token) {
    const payload = jwtDecode(token)
    const { exp } = payload
    const now = Math.ceil(Date.now() / 1000)
    return now > exp
  }

  sendTokenToServiceWorker() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(async registration => {
        if (registration.active) {
          registration.active.postMessage({ type: 'token', value: this.token});
        }
      });
    }
  }
}

export default AuthService
